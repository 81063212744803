import { Fragment, ReactNode, useEffect, useMemo, useState } from 'react';
import { MainSearchIcon } from '../../assets/icons/MainSearchIcon';
import EmptyStateIcon from '../../assets/icons/emptyStateIcon';
import { ModuleWithMirror, useModules } from '../../hooks/useModules';
import { getUniqueModuleKey } from '../utils/module';
import { Body1, H2 } from '../utils/typo';
import { ModuleCreate } from './ModuleCreate';
import { Skeleton } from '../utils/Skeleton';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { format } from 'date-fns';
import { ModuleStatus } from './ModuleStatus';
import { ModuleSetting } from './ModuleSetting';
import {
  ALGO_FLOW_ROUTE,
  CALC_FLOW_ROUTE,
  KB_CONTENT_ROUTE,
  MODULE_TYPES,
  MODULE_TYPES_MAP,
} from '../../constants';
import { twJoin, twMerge } from 'tailwind-merge';
import { ModuleName } from './ModuleName';
import { simulateInWebapp } from '../module-detail/ConversationBoardUtils';
import { generatePath, Navigate } from 'react-router-dom';
import { globalFilterFn } from '../utils/react-table/utils';
import { ArrowUp } from 'lucide-react';

export const ModuleList = () => {
  const [keyword, setKeyword] = useState('');
  const [statusTooltipIndex, setStatusTooltipIndex] = useState<number | undefined>(undefined);
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'last_updated',
      desc: true,
    },
  ]);
  const { teamModules, isLoading } = useModules();

  const [moduleForRedirection, setModuleForRedirection] = useState<ModuleWithMirror | undefined>(
    undefined
  );
  const openModule = (module: ModuleWithMirror) => {
    const isMirror = module?.type === MODULE_TYPES.MIRROR;
    const id = isMirror ? module.mirroredModuleId! : (module?.id as any); // TODO temporary fix
    const type = isMirror ? module.mirrorOf! : module?.type;

    if (isMirror && type !== MODULE_TYPES.KNOWLEDGE_BASE) {
      simulateInWebapp(id, type);
    } else {
      setModuleForRedirection(module);
    }
  };

  useEffect(() => {
    if (moduleForRedirection) {
      window.scrollTo(0, 0);
    }
  }, [moduleForRedirection]);

  const columns = useMemo<ColumnDef<ModuleWithMirror>[]>(
    () => [
      {
        id: 'title',
        header: 'Title',
        accessorKey: 'title',
        cell: (cell) => {
          const module = cell.row.original;
          return <ModuleName module={module} />;
        },
      },
      {
        id: 'owner',
        header: 'Owner',
        accessorKey: 'ownerName',
        cell: (cell) => (
          <div className='line-clamp-1 whitespace-nowrap'>
            <Body1>{cell.renderValue() as ReactNode}</Body1>
          </div>
        ),
      },
      {
        id: 'type',
        header: 'type',
        accessorKey: 'type',
        cell: (cell) => {
          const module = cell.row.original;
          const isMirror = module?.type === MODULE_TYPES.MIRROR;
          const type = isMirror ? module.mirrorOf! : module.type;
          return <Body1>{MODULE_TYPES_MAP[type]}</Body1>;
        },
      },
      {
        id: 'status',
        header: 'status',
        accessorKey: 'status',
        cell: (cell) => {
          const module = cell.row.original;
          return (
            <div className='whitespace-nowrap'>
              <ModuleStatus module={module} />
            </div>
          );
        },
      },
      {
        id: 'last_updated',
        header: 'Last Updated',
        accessorKey: 'last_updated',
        cell: (cell) => {
          const lastUpdated = cell.renderValue() as string;

          return (
            <Body1 className='text-gray-700'>
              {lastUpdated && format(new Date(lastUpdated), 'MM/dd/y')}
            </Body1>
          );
        },
      },
      { id: 'last_verified', accessorKey: 'last_verified' },
      { id: 'reviewed_by', accessorKey: 'reviewed_by' },
      { id: 'review_expire_at', accessorKey: 'review_expire_at' },
    ],
    []
  );

  const tableColumns = useMemo(
    () =>
      isLoading
        ? columns.map((column) => ({
            ...column,
            cell: () => <Skeleton className='h-[24px] bg-gray-200' />,
          }))
        : columns,
    [columns, isLoading]
  );
  const tableData = useMemo(
    () => (isLoading ? Array(1).fill({ is_in_current_team: true }) : teamModules),
    [teamModules, isLoading]
  );

  const { getHeaderGroups, getRowModel, getSelectedRowModel } = useReactTable({
    columns: tableColumns,
    data: tableData,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
      globalFilter: keyword,
    },
    globalFilterFn,
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    enableSortingRemoval: false,
    sortDescFirst: true,
    initialState: {
      columnVisibility: { last_verified: false, reviewed_by: false, review_expire_at: false },
    },
  });

  // TODO: change Redirect to Link
  if (moduleForRedirection) {
    const isMirror = moduleForRedirection?.type === MODULE_TYPES.MIRROR;
    const type = isMirror ? moduleForRedirection.mirrorOf! : moduleForRedirection.type;

    let link = '';
    switch (type) {
      case MODULE_TYPES.CALCULATOR:
        link = generatePath(CALC_FLOW_ROUTE, { moduleId: moduleForRedirection.id });
        break;
      case MODULE_TYPES.KNOWLEDGE_BASE:
        link = generatePath(KB_CONTENT_ROUTE, { moduleId: moduleForRedirection.id });
        break;
      case MODULE_TYPES.ALGO:
        // I'm not sure, but this seems to be the only one causing a type error: String(...)
        link = generatePath(ALGO_FLOW_ROUTE, { moduleId: String(moduleForRedirection.id) });
        break;
      default:
        break;
    }

    return (
      <Navigate
        to={link}
        state={{
          isMirror: isMirror,
          mirrorId: isMirror ? moduleForRedirection.id : null,
          mirrorName: isMirror ? moduleForRedirection.title : null,
        }}
      />
    );
  }

  return (
    <>
      <div className='flex items-center pb-[32px]'>
        <H2>Your List</H2>
        <div className='ml-auto'>
          <div className='box-border flex gap-[8px]'>
            <div className='flex h-[40px] w-[336px] items-center gap-[4px] rounded border border-gray-300 px-[12px] py-[10px]'>
              <MainSearchIcon />
              <input
                className='m-0 !h-[20px] w-[288px] !border-none text-[14px] leading-[20px] text-gray-900 placeholder-gray-300 shadow-none focus:border-none focus:outline-none'
                type='text'
                placeholder='Search modules'
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
              />
            </div>
            <ModuleCreate moduleCount={teamModules.length} />
          </div>
        </div>
      </div>
      <div>
        <table className='w-full'>
          {getHeaderGroups().map((headerGroup, idx) => (
            <thead className='border-b-2 border-gray-300' key={idx}>
              {headerGroup.headers.map((header) =>
                header.isPlaceholder ? null : (
                  <th
                    key={header.id}
                    className={twJoin(
                      'px-[8px] py-[16px] text-left',
                      header.column.getCanSort() && 'cursor-pointer select-none'
                    )}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    <div className='flex items-center'>
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      <ArrowUp
                        size={20}
                        className={twMerge(
                          'duration-150',
                          header.column.getIsSorted() === 'desc' && 'rotate-180',
                          header.column.getIsSorted() ? 'text-primary-600' : 'text-gray-300'
                        )}
                      />
                    </div>
                  </th>
                )
              )}
            </thead>
          ))}
          <tbody>
            {!isLoading && getRowModel().rows.length === 0 ? (
              <EmptyList keyword={keyword} />
            ) : (
              getRowModel().rows.map((row) => {
                const module = row.original;
                return (
                  <Fragment key={row.id}>
                    <tr
                      className={twJoin(
                        'relative cursor-pointer border-b border-gray-300 hover:bg-primary-100',
                        isLoading && 'pointer-events-none'
                      )}
                      data-testid={getUniqueModuleKey(module)}
                      onClick={() => openModule(module)}
                      onMouseEnter={() => setStatusTooltipIndex(row.index)}
                      onMouseLeave={() => setStatusTooltipIndex(undefined)}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id} className='px-[12px] py-[16px] first:pl-2 last:pr-0'>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      ))}
                      {!isLoading && (
                        <div onClick={(e) => e.stopPropagation()}>
                          <ModuleSetting
                            module={module}
                            show={statusTooltipIndex === row.index}
                            closeIcon={() => setStatusTooltipIndex(undefined)}
                          />
                        </div>
                      )}
                    </tr>
                  </Fragment>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

const EmptyList = ({ keyword }) =>
  keyword ? (
    <tr className='h-[60vh]'>
      <td align='center' colSpan={5}>
        <p className='text-center text-base font-semibold leading-6 text-gray-700'>
          No results found for '{`${keyword}`}'
        </p>
      </td>
    </tr>
  ) : (
    <tr className='h-[60vh]'>
      <td align='center' colSpan={5}>
        <EmptyStateIcon />
        <p className='mb-3 mt-12 text-center text-2xl font-bold leading-8 text-gray-900'>
          You don’t have any modules yet!
        </p>
        <p className='text-base font-normal leading-5 text-gray-700'>
          To make a module, click Create in the top right corner
        </p>
      </td>
    </tr>
  );
